import {
  getTemplateMeta,getTemplate,getTemplateData,getTemplateById
} from './tpp.action'
import { app as api } from '@ekuaibao/whispered'
import loadable from '@loadable/component'

// @i18n-ignore-all

export default [
  {
    id: '@inter-connection',
    path: '/inter-connection',
    ref: '/',
    onload: () => import('./inter-connection'),
    dependencies: ['@auth-check'],
    reducer: () => import('./tpp.reducer.js'),

    'get:template'(value) {
      return api.dispatch(getTemplate(value))
    },

    'get:template:meta'(value) {
      return api.dispatch(getTemplateMeta(value))
    },
    'get:template:data'(value) {
      return api.dispatch(getTemplateData(value))
    },
    'get:template:by:id'(id) {
      return api.dispatch(getTemplateById(id))
    },
  },
  {
    point: '@@menus',
    onload: () => {
      return [
        {
          id: 'inter-connection',
          pId: 'enterprise-manage',
          powers: ['YEEGO'],
          permissions: ['SYS_ADMIN'],
          weight: 101,
          label: '互联组件',
          href: '/inter-connection',
        },
      ]
    },
  },
  {
    point: '@@layers',
    prefix: '@inter-connection',
    onload: () => import('./layer'),
  },
  {
    resource: '@inter-connection',
    value: {
      ['view-edit/temp/index']: loadable(() => import('./view-edit/temp/index'))
    },
  },
]
