/**
 * Created by ZhangXingBo.
 */
export const ID = '@inter-connection'

export default {
  ID: ID,
  KEY_TAB: `${ID}/KEY_TAB`,
  KEY_TEMPLATEMETA:`${ID}/KEY_TEMPLATEMETA`,
  KEY_TEMPLATELIST:`${ID}/KEY_TEMPLATELIST`,
  KEY_TEMPLATEADD:`${ID}/KEY_TEMPLATEADD`,
  KEY_TEMPLATEEDIT: `${ID}/KEY_TEMPLATEEDIT`,
  KEY_GET_TEMPLATE_BY_ID: `${ID}/KEY_GET_TEMPLATE_BY_ID`,
  KEY_BILLDATALINK:`${ID}/KEY_BILLDATALINK`,
  KEY_TEMPLATECURRENT:`${ID}/KEY_TEMPLATECURRENT`,
  KEY_ENABLE_TEMPLATE:`${ID}/KEY_ENABLE_TEMPLATE`,
  KEY_GETTEMPLATE_DATA:`${ID}/KEY_GETTEMPLATE_DATA`,
}
