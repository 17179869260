import key from './key'
import { Resource } from '@ekuaibao/fetch'
const connect = new Resource('/api/connect')
import { app as api } from '@ekuaibao/whispered'


export function getTemplateMeta(value) {
  return {
    type: key.KEY_TEMPLATEMETA,
    payload: connect.GET(`/templateMeta`, { ...value }),
  }
}

export function AddTemplate(value) {
  return {
    type: key.KEY_TEMPLATEADD,
    payload: connect.POST(`/template`, { ...value }),
  }
}

export function EditTemplate(value) {
  return {
    type: key.KEY_TEMPLATEEDIT,
    payload: connect.PUT(`/template/$id`, { ...value }),
  }
}
export function getTemplateById(id) {
  return {
    type: key.KEY_GET_TEMPLATE_BY_ID,
    payload: connect.GET(`/template/$id`, {id }),
  }
}

export function getTemplate(value) {
  return {
    type: key.KEY_TEMPLATELIST,
    payload: connect.GET(`/template`, { ...value }),
  }
}

export function getBillDataLink(value) {
  return {
    type: key.KEY_BILLDATALINK,
    payload: {value},
  }
}

export function setTab(value) {
  return {
    type: key.KEY_TAB,
    payload: { value },
  }
}

export function setCurrent(value) {
  return {
    type: key.KEY_TEMPLATECURRENT,
    payload: { value },
  }
}
export function enableTemplate(id,enable) {
  return {
    type: key.KEY_ENABLE_TEMPLATE,
    payload: connect.POST(`/template/$id/actions/${enable?"active":"deactive"}`, {id}),
  }
}
export function getTemplateData(value) {
  return {
    type: key.KEY_GETTEMPLATE_DATA,
    payload: connect.GET(`/template/$id/data`, { ...value }),
  }
}